import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import GlobalStyle from '../styles/globalStyles'
import SEO from '../components/SEO'
import styled from "styled-components";
import Container from '../components/layout/Container'

const StyledText = styled.div`
  grid-column: 1 / span 6;
  margin-top: 48px;
  margin-bottom: 24px;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 6;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 8;
  }

  h1 {
    background: #FFDE0A;
    color: rgba(0,0,0,0.87);
    display: inline;
    font-family: Roboto Slab, serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.7;
    padding: 0.125rem 0;
    -webkit-box-shadow: 0.5rem 0px 0px #FFDE0A, -0.5rem 0px 0px #FFDE0A;
    box-shadow: 0.5rem 0px 0px #FFDE0A, -0.5rem 0px 0px #FFDE0A;
    -webkit-box-decoration-break:clone;
    -moz-box-decoration-break:clone; 
    box-decoration-break: clone;

    @media ${(props) => props.theme.breakpoints.xxs} {
      font-size: 34px;
    }
  
    @media ${(props) => props.theme.breakpoints.s} {
      font-size: 36px;
      margin-top: 32px;
      margin-bottom: 24px;
    }
  
    @media ${(props) => props.theme.breakpoints.m} {
      font-size: 48px;
      font-weight: 700;
      margin-top: 48px;
    }
  }

  h2 {
    color: ${(props) => props.theme.color.light};
    font-size: 18px;
    font-weight: 300;
  }

  p:first-of-type {
    margin-top: 24px;
  }
`

const NotFoundPage = () => (
  <Layout>
    <GlobalStyle />
    <SEO title="Not found" />
    <Container>
      <StyledText>
        <h1>Helaas...</h1>
        <p> Het lijkt er op dat we de pagina die je zocht, niet kunnen vinden. 
        Gebruik het menu hierboven of <Link to="/">ga terug naar de homepagina</Link>.</p>
      </StyledText>
    </Container>
  </Layout>
)

export default NotFoundPage
